@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;0,600;0,800;1,100;1,400;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
  font-family: "Exo", sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
